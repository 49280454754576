
import {Options, Vue} from 'vue-class-component';
import {VocService} from "@/service/VocService";
import {Activity, LevelStatsEntry, VocabularyRange, VocabularyRangeStore} from "@/model/model";
import {niceDay} from "@/service/utils";

interface GroupEntry {
  label: string,
  count?: number,
  emoji?: string,
  predicate: (learned: number, level: number) => boolean
}

@Options({
  components: {},
  filters: {}
})
export default class StatsView extends Vue {

  private languageModifier = 2;  // languageModifier = für Sprachen mit 2 Richtungen
  private stats: Array<LevelStatsEntry> = [];

  levelSteps = [2, 5, 9]; // einfache Richtung wie z.B. Latein. Doppelt bei Englisch.
  private niceDay = niceDay;

  private vocabRange?: VocabularyRange;

  groups: GroupEntry[] = [
    {
      label: 'Neue Vokabeln',
      count: 0,
      emoji: '🆕',
      predicate: (learned: number, level: number) => learned == 0,
    },
    {
      label: 'Frisch gelernt',
      count: 0,
      emoji: '📚',
      predicate: (learned: number, level: number) => learned > 0 && level == 0,
    },
    {
      label: 'Stufe 1',
      count: 0,
      emoji: '1️⃣',
      predicate: (learned: number, level: number) => learned > 0 && level > 0 && level <= this.levelSteps[0] * this.languageModifier,
    },
    {
      label: 'Stufe 2',
      count: 0,
      emoji: '2️⃣',
      predicate: (learned: number, level: number) => learned > 0 && level > this.levelSteps[0] * this.languageModifier && level <= this.levelSteps[1] * this.languageModifier,
    },
    {
      label: 'Stufe 3',
      count: 0,
      emoji: '3️⃣',
      predicate: (learned: number, level: number) => learned > 0 && level > this.levelSteps[1] * this.languageModifier && level <= this.levelSteps[2] * this.languageModifier,
    },
    {
      label: 'Endstufe',
      count: 0,
      emoji: '🎉',
      predicate: (learned: number, level: number) => learned > 0 && level > this.levelSteps[2] * this.languageModifier,
    }

  ];

  groupsRanged: GroupEntry[] = this.groups.map(group => ({...group, count: 0}));

  private activity: Array<Activity> = [];

  mounted() {
    const langPair = this.$route.params.langUuid as string;
    this.vocabRange = VocabularyRangeStore.load(langPair)

    VocService.get().getLanguage(langPair)
        .then(lang => {
          this.languageModifier = lang?.both_directions == 0 ? 1 : 1.5;
          this.updateGroupsWithStats(this.groups, this.stats);
        });

    VocService.get().getLevelStats(langPair) // alle
        .then(stats => {
          this.updateGroupsWithStats(this.groups, stats);
          this.stats = stats; //merken, damit man die später neu berechnen könnte
        });

    VocService.get().getLevelStats(langPair, this.vocabRange) // nur die in der range
        .then(stats => {
          this.updateGroupsWithStats(this.groupsRanged, stats);
        });


    VocService.get().getActivity(langPair).then(activity => {
      this.activity = activity;
    })
  }

  private updateGroupsWithStats(groups: GroupEntry[], levelStats: Array<LevelStatsEntry>) {
    // am Anfang alle Groups zurücksetzen
    groups.forEach(group => group.count = 0);

    //die stats sind bereits nach level sortiert
    levelStats.forEach(statsEntry => {
      const suitableGroup = groups.find(group => group.predicate(statsEntry.learned, statsEntry.level));
      if (suitableGroup) {
        suitableGroup.count = (suitableGroup.count || 0) + (statsEntry.count || 0);
      }
    })
  }

}
